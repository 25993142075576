<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <h2 class="title">
        <div class="title_icon"></div>
        添加监控设备
      </h2>
      <!--搜索条件区域-->
      <div class="Wrapper" @keydown.enter.prevent="submitData">
        <el-form
          label-position="right"
          label-width="100px"
          :model="formInline"
          style="width: 300px; float:left;"
          :rules="rules"
          :ref="'form'"
        >
          <el-form-item :label="$t('searchModule.Equipment_name')" prop="monitorEquipmentName">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.monitorEquipmentName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
            <el-select
              v-model.trim="formInline.operationId"
              @change="operationChange"
              filterable
              size="15"
            >
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Belonging_parking_lot')" prop="parkId">
            <auto-complete
              ref="select"
              :operationIdRequired="true"
              :operationId="formInline.operationId"
              slaveRelations="0,2"
              @valueChange="completeValue"
            ></auto-complete>
          </el-form-item>
          <!-- <el-form-item :label="$t('searchModule.Pole_position_number')" prop="barCode">
            <el-autocomplete
              class="inline-input"
              v-model="state1"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item> -->
          <el-form-item label="经纬度" prop="longitude">
            <el-input v-model.trim="lnglat" disabled placeholder="请选择车场"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.address')" prop="address">
            <el-input
              :maxlength="50"
              type="textarea"
              disabled
              v-model.trim="formInline.address"
              placeholder="输入车场详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="IP地址" prop="ipAddress">
            <el-input
              :maxlength="15"
              v-model.trim="formInline.ipAddress"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="端口号" prop="ipPort">
            <el-input
              :maxlength="10"
              v-model.trim="formInline.ipPort"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="视频端口号" prop="monitorPort">
            <el-input
              :maxlength="10"
              v-model.trim="formInline.monitorPort"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="account">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.account"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.password"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: left;  margin-left: 8.5%; ">
        <el-button type="primary" @click="submitData" style="width: 88px;">{{ $t('button.preservation') }}</el-button>
        <el-button type="" @click="$router.go(-1)" style="width: 88px;">取消</el-button>
      </div>
    </div>
    <map-con ref="mapConpent" @mapCancel="mapCancel" @mapSure="mapSure"></map-con>
  </div>
</template>
<script>
import autoComplete from "@/components/autocomplete";
import mapCon from "@/components/map";
export default {
  name: "hello",
  data() {
    let validateIpPort = (rule, value, callback) => {
      let reg = /^[0-9]+.?[0-9]*$/;
      if (reg.test(value)) {
        this.formInline.ipPort = parseInt(value);
        if (this.formInline.ipPort >= 65535) {
          callback("端口号应小于65535");
        } else {
          callback();
        }
      } else {
        callback("端口号请输入纯数字");
      }
    };
    let validateMonitorPort = (rule, value, callback) => {
      let reg = /^[0-9]+.?[0-9]*$/;
      if (reg.test(value)) {
        this.formInline.monitorPort = parseInt(value);
        if (this.formInline.monitorPort >= 65535) {
          callback("视频端口号应小于65535");
        } else {
          callback();
        }
      } else {
        callback("视频端口号应为纯数字");
      }
    };
    return {
      flag: false, // 是否能够提交
      parkPage: 1,
      parkName: "",
      dis: false,
      lnglat: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      parkData: [],
      parkTypeAll: [],
      parkList: "",
      areaList: [],
      isEdit: false,
      loading: false,
      startTime: "",
      tenantList: [],
      formInline: {
        parkId: "",
        operationId: "",
        monitorEquipmentName: "",
        address: "",
        latitude: "",
        longitude: "",
        ipAddress: "",
        ipPort: "",
        monitorPort: "",
        account: "",
        password: ""
      },
      rules: {
        parkId: [
          {
            required: true,
            message: "必填",
            trigger: ["blur", "change"]
          }
        ],
        operationId: [
          {
            required: true,
            message: "必填",
            trigger: ["blur", "change"]
          }
        ],
        monitorEquipmentName: [
          {
            required: true,
            message: "必填",
            trigger: "blur"
          }
        ],
        address: [
          {
            required: true,
            message: "必填",
            trigger: "blur"
          }
        ],
        latitude: [
          {
            required: true,
            message: "必填",
            trigger: "blur"
          }
        ],
        longitude: [
          {
            required: true,
            message: "必填",
            trigger: "blur"
          }
        ],
        ipAddress: [
          {
            required: true,
            message: "必填",
            trigger: "blur"
          }
        ],
        ipPort: [
          {
            required: true,
            message: "必填",
            trigger: "blur"
          },
          { validator: validateIpPort, trigger: "blur" }
        ],
        monitorPort: [
          {
            required: true,
            message: "必填",
            trigger: "blur"
          },
          { validator: validateMonitorPort, trigger: "blur" }
        ],
        account: [
          {
            required: true,
            message: "必填",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "必填",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    operationChange() {
      this.$refs.select.setValue();
    },
    completeValue(item) {
      this.formInline.parkId = item;
      if (!item) return;
      this.getParkDetail(item);
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then(res => {
        this.loading = false;
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },
    openMap() {
      this.$refs.mapConpent.openMap(this.lnglat);
    },
    mapCancel() {
      this.$refs.mapConpent.mapClose();
      // this.formInline.parkAddress = '';
      // this.lnglat = '';
    },
    mapSure(opt) {
      this.formInline.latitude = opt.temLat * 1000000;
      this.formInline.longitude = opt.temLng * 1000000;
      this.formInline.address = opt.parkAddress;
      this.lnglat = opt.hasSelect;
      this.$refs.mapConpent.mapClose();
    },
    getParkDetail(parkId) {
      let url = "/acb/2.0/park/" + parkId;
      this.$axios.get(url).then(res => {
        if (res.state == 0) {
          let data = res.value;
          this.lnglat = data.longitude / 1000000 + "," + data.latitude / 1000000;
          this.formInline.latitude = data.latitude;
          this.formInline.longitude = data.longitude;
          this.formInline.address = data.parkAddress;
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },
    submitData() {
      this.$refs["form"].validate(valid => {
        if (this.formInline.ipPort >= 65535) {
          this.$alert("端口号应小于65535", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
          return;
        }
        if (valid) {
          this.formInline.ipPort = parseInt(this.formInline.ipPort);
          this.formInline.monitorPort = parseInt(this.formInline.monitorPort);
          if (this.formInline.ipPort >= 65535 || this.formInline.monitorPort >= 65535) {
            this.$alert("端口号应小于65535", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            return;
          } else if (!(typeof this.formInline.ipPort == "number")) {
            this.$alert("端口号应为纯数字", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            return;
          }
          this.$axios
            .post("/acb/2.0/monitorEquipment/add", {
              data: this.formInline
            })
            .then(res => {
              if (res.state == 0) {
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                }).then(() => {
                  this.$router.go(-1);
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                });
              }
            });
        } else {
          return false;
        }
      });
    }
  },
  components: {
    mapCon,
    autoComplete
  },
  created() {
    if (this.$route.query.parentId) {
      this.isEdit = true;
    }
    this.getTenantList();
  },
  mounted() {},
  computed: {}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper
  overflow hidden
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  border 1px solid #C0CCDA
  border-radius 4px
  padding 20px
.uploadPic
  float left
  margin-left 150px
</style>
<style>
.avatar-uploader2 .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader2 .el-upload:hover {
  border-color: #0f6eff;
}
.avatar-uploader-icon2 {
  font-size: 28px;
  color: #8c939d;
  width: 295px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar2 {
  width: 295px;
  height: 178px;
  display: block;
}
</style>
